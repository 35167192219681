<template>
    <div class="content md">
        <calendar lunar @month="val => current = val">
            <template v-slot:full="{day}">
                <div class="date-buddha-box">
                    <div class="buddha-date-info">
                        <div class="buddha-date-num">{{day.day}}</div>
                        <div class="buddha-date-lunar">{{day.lunar.lnongDate == "初一" ? `${day.lunar.lnongMonth}月` : day.lunar.lnongDate}}</div>
                    </div>
                    <div class="date-buddha" v-if="dateMap[day.dateStr]" @click.stop>
                        <div class="date-buddha-item" v-for="(b, i) in dateMap[day.dateStr]" :key="i">
                            <a-popover trigger="click" v-if="b.cat == 1">
                                <div class="pop-buddha-name" slot="title">
                                    <span>{{b.name}}</span>
                                    <div class="txt-btn" @click="$router.push({name: 'BuddhismDetail', params: {id: b.id}})">查看详情<a-icon type="right" /></div>
                                </div>
                                <div class="date-buddha-ctx" slot="content">
                                    <div class="date-buddha-info">具体时间：{{b.timeText}}</div>
                                    <div class="date-buddha-info">殿堂：{{b.palace || '未指定'}}</div>
                                    <div class="date-buddha-info" v-if="b.contact_person">联系人：{{b.contact_person}}</div>
                                </div>
                                <div class="date-buddha-name" :style="{backgroundColor: b.status == 6 ? '#ccc' : colors[i % (colors.length - 1)]}">
                                    {{b.name}}
                                    <a-icon :type="b.cancel ? 'close' : 'check'" class="date-buddha-state" />
                                </div>
                            </a-popover>
                            <div class="date-buddha-name" :style="{backgroundColor: colors[i % (colors.length - 1)]}" v-else>{{b.name}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </calendar>
    </div>
</template>

<script>
import calendar from "../../components/calendar/calendar";
import {getFhList, getMonthBuddhismList, getMonthBuddhismMap, buddhismColors} from "../../common/buddha/buddhism";

export default {
    name: "BuddhaBoard",
    components: {
        calendar
    },
    data() {
        return {
            current: null,
            pujaList: null,
            buddhaList: null,
            colors: buddhismColors,
            dateMap: {}
        }
    },
    watch: {
        current() {
            this.getMonthBuddhismList();
        },
    },
    created() {
        this.getPujaList();
    },
    methods: {
        getPujaList() {
            getFhList().then(list => {
                this.pujaList = list;
                this.generateCalendarView();
            })
        },
        getMonthBuddhismList() {
            if(!this.current) return;
            getMonthBuddhismList(this.current).then(list => {
                this.buddhaList = list;
                this.generateCalendarView()
            })
        },
        generateCalendarView() {
            if(this.pujaList && this.buddhaList) {
                this.dateMap = getMonthBuddhismMap(this.current, this.pujaList, this.buddhaList);
            }
        }
    }
}
</script>

<style scoped lang="less">
    .date-buddha-box {
        padding: 6px 12px;
        min-height: 100px;
    }
    .buddha-date-info {
        display: flex;
        justify-content: space-between;
    }
    .date-buddha {
        margin-top: 8px;
    }
    .date-buddha-item {
        color: @white;
        &:not(:first-of-type) {
            margin-top: 4px;
        }
    }
    .date-buddha-name {
        border-radius: 4px;
    }
    .date-buddha-state {
        margin-left: .5em;
    }
    .date-buddha-ctx {
        font-size: 12px;
        .txt-btn {
            text-align: center;
        }
    }
    .date-buddha-info {
        margin-bottom: 6px;
    }
    .pop-buddha-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0;
        .txt-btn {
            font-size: 12px;
        }
    }
</style>
