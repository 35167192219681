<template>
  <buddhism-enroll-list ref="buddhismList" type="claim" class="piece-area buddha-order-list"></buddhism-enroll-list>
</template>

<script>

    import BuddhismEnrollList from "./BuddhismEnrollList";
    export default {
        name: "BuddhismClaim",
        components: {
            BuddhismEnrollList
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>
