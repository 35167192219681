<template>
    <div class="content md flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <span>共{{pagination.total}}条记录</span>
            </div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="大类">
                    <a-select
                        style="width: 180px"
                        placeholder="选择大类筛选"
                        @change="getList"
                        :options="categoryList"
                        v-model="form.cat">
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="状态">
                    <a-select
                      style="width: 180px"
                      placeholder="选择状态筛选"
                      @change="getList"
                      :options="statusList"
                      v-model="form.status">
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <a-button type="primary" @click="addBuddhaType">新增</a-button>
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:name="text">
                    <open-data type="userName" :openid="text" />
                </template>
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="setState(record)">{{record.status == 0 ? '开启' : '关闭'}}</a>
                        <a class="txt-btn" @click.stop="showDetail(record)">设置</a>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import {buddhaCategoryList, getBuddhaCategoryName, getBuddhaTypeName} from "../../common/buddha/type";
    import {isEmpty} from "../../common/js/tool";

    export default {
        name: "BuddhismList",
        data() {
            return {
                loading: false,
                list: [],
                form: {},
                h: 500,
                columns: [
                    {title: '名称', dataIndex: 'name'},
                    {title: '所属大类', dataIndex: 'cat',customRender: text => getBuddhaCategoryName(text)},
                    {title: '类别', dataIndex: 'fs_type',customRender: text => getBuddhaTypeName(text)},
                    {
                        title: '状态',
                        dataIndex: 'status',
                        customRender: text => {
                            return <span>{text == 1 ? '启用' : '停用'}</span>
                        }
                    },
                    {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                ],
                categoryList: [{key: 0, title: '全部'}].concat(buddhaCategoryList),
                statusList: [
                    {key: -1, title: '全部'},
                    {key: 0, title: '停用'},
                    {key: 1, title: '启用'},
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
            }
        },
        created() {
            // this.getBuddhismCategory();
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            setState(record) {
                let url = `/admin/fs-temp/${record.id}`;
                this.$axios({
                    url,
                    method: "PATCH",
                    data: {
                        status: record.status == 0 ? 1 : 0
                    }
                }).then(res => {
                    this.$set(record, 'status', res.status);
                })
            },
            showDetail(record) {
                this.$router.push({name: 'BuddhaTypeDetail', params: {id: record.id}});
            },
            addBuddhaType() {
                this.$router.push({name: 'BuddhaTypeAdd'})
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                if(this.loading) return;
                this.loading = true
                let url = '/admin/fs-temp';
                let {current, pageSize} = this.pagination;
                url += `?page=${current}&pageSize=${pageSize}`;
                let form = this.form;
                if(form.cat > 0) {
                    url += `&filter[cat]=${form.cat}`;
                }
                if(!isEmpty(form.status) && form.status != -1) {
                    url += `&filter[status]=${form.status}`;
                }
                this.$axios(url).then(res => {
                    this.list = res.data;
                    this.$set(this.pagination, 'total', res.page.totalCount);
                }).finally(() => {
                    this.loading = false;
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped>

</style>
